import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Branding from "../components/testimonial";
import VFInNumbers from "../components/vf-in-numbers";
import OurApproach from "../components/our-approach";
import SubscribeNewsLetter from "../components/subscribe-newsletter";
import UpcomingEvents from "../components/upcoming-events";
import PortfolioSection from "../components/portfolio-section";
const eifLogo = require("../images/eif-logo.jpg");
const euLogo = require("../images/flag-eu-erdf-eng.jpg");
const epanekLogo = require("../images/epanek.png");

const IndexPage = ({ data }) => {
  const [displayAccreditation, setDisplayAccreditation] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      if (window.scrollY <= 5) {
        setDisplayAccreditation(true);
      } else {
        setDisplayAccreditation(false);
      }
      return () => window.removeEventListener("scroll", () => {});
    });
  });

  return (
    <Layout>
      <SEO
        title="VentureFriends"
        description="We are an early stage investor investing in Seed and Series A startups."
      />
      <Branding
        images={[
          data.bluegroundTestimonial.nodes[0].fluid,
          data.huspyTestimonial.nodes[0].fluid,
          data.instashopTestimonial.nodes[0].fluid,
          data.belvoTestimonial.nodes[0].fluid,
        ]}
      />

      <VFInNumbers data={data.allStrapiVfInNumbers.edges[0].node} />
      <PortfolioSection
        data={data.allStrapiStartups.nodes.filter(
          (x) => x.visibility !== "Involved With"
        )}
      />
      <OurApproach image={data.ourApproach.nodes[0].fluid} />
      <div className="mb-10 md:mb-10 lg:mb-14 ">
        <SubscribeNewsLetter heading="Stay in the loop" />
      </div>
      {displayAccreditation && (
        <div className="bg-white hidden md:flex fixed bottom-0 left-0 p-4 shadow radius">
          <img src={euLogo} alt="euLogo" className="h-15 mr-4" />
          <img src={eifLogo} alt="eifLogo" className="h-15" />
          <img
            src={epanekLogo}
            alt="epanekLogo"
            className="h-10 ml-3 self-center"
          />
        </div>
      )}
      {/* <UpcomingEvents data={data.allStrapiEvents.nodes} /> */}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query getNumbers {
    allStrapiVfInNumbers(limit: 1) {
      edges {
        node {
          companiesFunded
          geographicalFocus
          invRatio
          minInitialTicket
          latestInvestmentRaised
          lgtInitialTicket
          targetStage
          totalAUM
        }
      }
    }
    allStrapiStartups(
      filter: { published: { eq: true } }
      sort: { fields: homepageSorting }
    ) {
      nodes {
        logo {
          # childImageSharp {
          #   fluid(maxWidth: 250) {
          #     ...GatsbyImageSharpFluid
          #   }
          # }
          publicURL
        }
        name
        slug
        applicableCategories {
          id
          name
        }
        homepageSorting
        shortDescription
        headquarter
        status
        exitDescription
        visibility
      }
    }
    allStrapiCategories {
      nodes {
        name
        strapiId
      }
    }
    # allStrapiEvents(sort: {fields: Date, order: DESC}) {
    #   nodes {
    #     title
    #     subtitle
    #     speaker
    #     getTicketURL
    #     description
    #     Date
    #   }
    # }
    bluegroundTestimonial: allImageSharp(
      filter: { fluid: { originalName: { eq: "1_Blueground.jpg" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    instashopTestimonial: allImageSharp(
      filter: { fluid: { originalName: { eq: "2_Instashop.jpg" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    huspyTestimonial: allImageSharp(
      filter: { fluid: { originalName: { eq: "huspy.png" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    belvoTestimonial: allImageSharp(
      filter: { fluid: { originalName: { eq: "belvo.jpeg" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ourApproach: allImageSharp(
      filter: { fluid: { originalName: { eq: "approach@3x.jpg" } } }
    ) {
      nodes {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
