import React from "react";
import SectionTitle from "./section-title";
import Event from "./event";
function UpcomingEvents({data: events}) {
  return (
    <div className="mb-12 md:mb-16 lg:mb-20">
      <div className="flex justify-between mb-1 md:mb-8 items-center">
        <SectionTitle title="Upcoming Events" />
      </div>
      <div className="md:-mr-32 md:overflow-x-scroll flex flex-col md:flex-row py-6 md:py-0">
        {events.map((event) => (
          <Event data={event} />
        ))}
      </div>
    </div>
  );
}

export default UpcomingEvents;
