import React from "react";

function SectionTitle({ title, className, ...others }) {
  return (
    <h2
      className={`text-3xl md:text-5xl text-secondary font-bold ${className}`}
    >
      {title}
    </h2>
  );
}

export default SectionTitle;
