import React from "react";
import SectionTitle from "./section-title";
import Button from "./button";
import approachImage1x from "../images/approach.jpg";
import approachImage2x from "../images/approach@2x.jpg";
import approachImage3x from "../images/approach@3x.jpg";
import Img from "gatsby-image";
import ToFoundersTypeWriter from "./TypeWriterTexts/to-founders";
import UtilizeOurTypeWriter from "./TypeWriterTexts/utilize-our";
import {Link} from "gatsby";

function OurApproach({image}) {
  return (
    <div className="flex flex-row flex-wrap md:flex-row-reverse border-t-2 border-b-2 border-solid border-primary pt-10 md:pt-0 pb-10 md:pb-0 mb-10 md:mb-10 lg:mb-14">
      <div className="w-full block md:w-6/12">
        <Img
          className="w-full md:w-auto md:max-h-100 object-contain"
          fluid={image}
          alt="our approach"
        />
      </div>
      <div className="w-full md:w-6/12 lg:pr-16  flex flex-col justify-center">
        <h3 className="text-4xl mt-1 mb-6">
          We
          <strong>
            <ToFoundersTypeWriter />
          </strong>
          our founders and utilise our
          <strong>
            <UtilizeOurTypeWriter />
          </strong>
          in their favour
        </h3>

        <Link
          className="h-11 inline-flex justify-center items-center border-2 border-solid  text-primary  border-primary text-sm pr-4 pl-4 whitespace-no-wrap w-full md:w-29"
          to="/about"
        >
          Learn more
        </Link>
        {/* <Button fontSize='sm' className='w-full md:w-29'>
          Learn more
        </Button> */}
      </div>
    </div>
  );
}

export default OurApproach;
