import React, { useState, useEffect } from "react";
import SectionTitle from "./section-title";
import { Link } from "gatsby";
import Button from "./button";
import Img from "gatsby-image";
import downArrow from "../images/down-black.svg";
import rocket from "../images/rocket.svg";

const STATUS = {
  EXIT: "Exit",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

function PortfolioSection({ data: portfolio, categories: receivedCategories }) {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [startups, setStartups] = useState([]);
  const [categoryDDOpen, toggleCategoryDDOpen] = useState(false);
  const [isExpanded, toggleIsExpanded] = useState(false);
  // const [categories, setCategories] = useState([]);

  const categories = [
    "All",
    "PropTech",
    "FinTech",
    // "TravelTech",
    "Marketplaces",
    "SaaS",
    "Other",
  ];

  useEffect(() => {
    if (selectedCategory === "All") {
      setStartups(portfolio.filter((f) => f.status !== STATUS.INACTIVE));
    } else {
      setStartups(
        portfolio.filter(
          (p) =>
            p.status !== STATUS.INACTIVE &&
            p.applicableCategories.find((ac) => ac.name === selectedCategory)
        )
      );
    }
  }, [selectedCategory, portfolio]);

  const ExpandText = ({ children }) => (
    <div
      className="text-primary text-sm flex justify-center md:hidden cursor-pointer"
      onClick={() => toggleIsExpanded(!isExpanded)}
    >
      {children}
    </div>
  );

  return (
    <div className=" mb-12 md:mb-20">
      <div className="flex justify-between mb-6 md:mb-12 items-center">
        <SectionTitle title="Portfolio" />
      </div>
      <div>
        <div className="flex flex-wrap justify-between">
          <ul className="hidden md:flex mb-10 ">
            {categories.map((category) => (
              <li
                className={`text-lg text-primary text-opacity-25 font-bold p-2 md:mr-4 lg:mr-8 cursor-pointer 
            hover:border-b-2 hover:text-opacity-100 hover:border-solid hover:border-primary ${
              category === selectedCategory
                ? `text-opacity-100 border-b-2 border-solid border-primary`
                : ``
            }`}
                key={category}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
          </ul>

          <div className="flex justify-between mb-6 md:hidden">
            <div className="relative">
              <Button
                className="mb-2 text-secondary"
                onClick={() => {
                  toggleCategoryDDOpen(!categoryDDOpen);
                }}
              >
                <div className="flex items-center">
                  <span className="font-bold mr-1">{selectedCategory}</span>

                  <img
                    src={downArrow}
                    alt="expand"
                    className={`w-4 h-4 ${
                      categoryDDOpen ? "transform rotate-180" : ""
                    }`}
                  />
                </div>
              </Button>

              {categoryDDOpen ? (
                <div className="w-48 absolute p-2 bg-white shadow rounded z-50">
                  <ul className="divide divide-y  divide-primary divide-opacity-50">
                    {categories.map((category) => (
                      <li
                        className="flex justify-center py-2"
                        key={category}
                        onClick={() => {
                          setSelectedCategory(category);
                          toggleCategoryDDOpen(!categoryDDOpen);
                        }}
                      >
                        {category}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <Link
            className="h-11 justify-center items-center border-2 border-solid  text-primary  border-primary text-sm pr-4 pl-4 whitespace-no-wrap inline-flex"
            to="/portfolio"
          >
            Explore our Portfolio
          </Link>
        </div>

        <div className="startup-grid-home hidden md:grid grid-cols-6 row-gap-4 items-center">
          {startups.map((startup, index) => (
            <div className="relative p-2 startup-tile" key={index}>
              {startup.logo && (
                <Link to={`/portfolio/${startup.slug}`}>
                  <img
                    className="startup-img mx-auto  cursor-pointer  object-contain"
                    key={`desktop-${startup.name}`}
                    alt={`${startup.name}`}
                    src={startup.logo.publicURL}
                  />

                  <div className="startup-details hidden opacity-100 bg-white w-64 p-4 shadow rounded absolute inset-30 bottom-auto right-auto flex-col z-10">
                    <div className="relative">
                      <h3 className="font-bold mb-6">{startup.name}</h3>
                      {startup.status === "Exit" && (
                        <div className="bg-primary text-white font-bold uppercase block absolute inset-1 left-auto bottom-auto px-2">
                          {startup.status}
                        </div>
                      )}
                      <span className="block text-gray uppercase text-xxs mb-1">
                        Description
                      </span>
                      <span className="block text-sm font-medium mb-4">
                        {startup.shortDescription}
                      </span>

                      {startup.applicableCategories && (
                        <>
                          <span className="block text-gray uppercase text-xxs mb-1">
                            Sectors
                          </span>
                          <span className="block text-sm font-medium mb-4">
                            {startup.applicableCategories
                              .map((ac) => ac.name)
                              .join(",")}
                          </span>
                        </>
                      )}
                      <span className="block text-gray uppercase text-xxs mb-1">
                        Headquarters
                      </span>
                      <span className="block text-sm font-medium mb-6">
                        {startup.headquarter}
                      </span>

                      {startup.exitDescription && (
                        <>
                          {/* <span className="block text-gray uppercase text-xxs mb-1">
                            Status
                          </span> */}
                          <span className="block text-sm font-medium  mt-2 mb-6">
                            {startup.exitDescription}
                          </span>
                        </>
                      )}

                      <Button
                        secondary
                        className=" w-full font-medium"
                        fontSize="sm"
                      >
                        Learn more
                      </Button>
                    </div>
                  </div>
                  {startup.status === "Exit" ? (
                    <img
                      src={rocket}
                      alt="exited"
                      className={`w-8 h-8 absolute inset-2 bottom-auto left-auto`}
                    />
                  ) : null}
                </Link>
              )}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-3 row-gap-4 mb-2 md:hidden items-center">
          {startups
            .slice(0, isExpanded ? undefined : 12)
            .map((startup, index) => (
              <div className="relative p-2 startup-tile" key={index}>
                {startup.logo && (
                  <Link className="p-2" to={`/portfolio/${startup.slug}`}>
                    <img
                      className="mx-auto  cursor-pointer object-contain"
                      src={startup.logo.publicURL}
                      title={startup.name}
                      key={`mob-${startup.name}`}
                    />
                    {startup.status === STATUS.EXIT && (
                      <img
                        src={rocket}
                        alt="exited"
                        className={`w-8 h-8 absolute inset-2 bottom-auto left-auto`}
                      />
                    )}
                  </Link>
                )}
              </div>
            ))}
        </div>
        {(selectedCategory === "All" && portfolio.length > 12) ||
        portfolio.filter((p) =>
          p.applicableCategories.find((ac) => ac.name === selectedCategory)
        ).length > 12 ? (
          !isExpanded ? (
            <ExpandText>See more</ExpandText>
          ) : (
            <ExpandText>Less</ExpandText>
          )
        ) : null}
      </div>
    </div>
  );
}

export default PortfolioSection;
