import React from "react";

import {
  TestimonialQuote,
  NavButtonMobileWrapper,
} from "./testimonial-components";
import PageHeading from "./page-heading";
import Img from "gatsby-image";
import {useState} from "react";

function Branding({images}) {
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(0);
  return (
    <div className=" md:border-b-2 border-solid border-primary  mb-20">
      <div className="-mx-4 lg:-mx-32 flex flex-wrap">
        <div className="w-full py-10 px-8 lg:pl-32 md:w-6/12 flex flex-col justify-center">
          <PageHeading>
            Investors
            <br className="hidden md:block" /> who <br className="md:hidden" />{" "}
            <span className="underline-primary">love</span>
            <br className="hidden md:block" /> startups
          </PageHeading>
          <h3 className="text-2xl md:text-3xl-vw text-primary">
            Invest - Help - Grow
          </h3>
        </div>
        <div className="w-full md:w-6/12 relative">
          {/* <img src={brandingImag} alt="branding pic" /> */}

          <Img
            fluid={images[activeTestimonialIndex]}
            alt="branding image"
            className="h-100 w-full md:h-auto object-cover"
          />
          <div className="absolute top-auto inset-y-8 inset-x-6 md:inset-x-6 md:inset-y-6 md:top-auto md:w-9/12">
            <TestimonialQuote
              className="hidden lg:block "
              theme="secondary"
              activeTestimonialIndex={activeTestimonialIndex}
              onChange={setActiveTestimonialIndex}
            />
            <NavButtonMobileWrapper
              activeTestimonialIndex={activeTestimonialIndex}
              onChange={setActiveTestimonialIndex}
              className="flex lg:hidden"
            />
          </div>
          {/* <Testimonials
            
            onChange={(index) => setActiveTestimonialIndex(index)}
            activeTestimonialIndex={activeTestimonialIndex}
          /> */}
        </div>
        <TestimonialQuote
          theme="primary"
          className="lg:hidden"
          activeTestimonialIndex={activeTestimonialIndex}
          onChange={setActiveTestimonialIndex}
        />
      </div>
    </div>
  );
}

export default Branding;
