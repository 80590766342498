import React, { useState } from "react";

import left from "../images/left.svg";
import right from "../images/right.svg";

const buttonClasses = `w-10 h-10 bg-primary  flex items-center justify-center focus:outline-none`;
const testimonials = [
  {
    title: "VF is not your typical investor",
    description:
      "They go way beyond that. Apostolos, George, and the team have been extremely supportive and effective as investors, operators, connectors, mentors, idea generators, and friends. VF has been by our side in good and tough times - and tough times are when you need your investors the most.",
    name: "Alex Chatzieleftheriou",
    designation: "CEO - Blueground",
  },
  {
    title: "VF is an extension to our internal team",
    description:
      "The VF team has been a partner of Huspy since the initial days and has been doubling down in each round after. George D, George K, and Apostolos have been a true support system and encourage us to be more bold, ambitious, and global. We see VF as an extension to our internal team and it's very refreshing to work with investors that are this friendly but also share the same vision as you do.",
    name: "Jad Antoun",
    designation: "CEO - Huspy",
  },
  {
    title: "Going the extra mile",
    description:
      "Having a fund on your cap table that truly goes the extra mile to understand and support the success of founders and founder-led organisations is the dream of any founder and that's exactly what we found in VF. The strong knowledge of marketplaces and the healthy and balanced way of working along with a strong global network have been of great importance for the success of InstaShop.",
    name: "John Tsioris",
    designation: "CEO - Instashop",
  },
  {
    title: "VentureFriends is an expansion of your team",
    description:
      "Venture Friends were one of the first funds backing Belvo when we were just starting. They have been next to us as close as needed, always willing to help and discuss ideas and strategy. Not only do they provide their deep market knowledge, but you can trust them as part of your team, willing to make the best decisions for the company even if they are not the optimal decision for the fund. Having Venture Friends next to you feels like an expansion of your team, and this is exactly what we look for in investors.",
    name: "Uri and Pablo",
    designation: "Co-CEOs - Belvo",
  },
  // {
  //   title: "Making things happen!",
  //   description:
  //     "The VF team led by Apostolos and George offers a unique blend of enthusiasm, knowledge, realism and vision. Their founder-centric mindset has proved quite important factors for Spotawheel whenever critical milestones were about to be achieved. Thought partners and true friends, always there to act above and beyond, we feel lucky to have them on our side since the very beginning.",
  //   name: "Charis Arvanitis",
  //   designation: "CEO - Spotawheel",
  // },
];
function NavButtonDesktop({
  nextOrPrev,
  onClick,
  disabled,
  className,
  ...others
}) {
  return (
    <button
      className={`${buttonClasses} ${
        disabled ? "bg-opacity-50" : "cursor-pointer"
      } ${className} `}
      onClick={onClick}
      {...others}
    >
      {nextOrPrev === "next" ? (
        <img src={right} alt="next-button" />
      ) : (
        <img src={left} alt="prev-button" />
      )}
    </button>
  );
}

function NavButtonMobile({ active, onClick }) {
  return (
    <button
      className={`w-12 h-3 bg-white ${
        active ? "" : "bg-opacity-25"
      } mx-1 focus:outline-none`}
      onClick={onClick}
    ></button>
  );
}

function NavButtonMobileWrapper({
  activeTestimonialIndex,
  onChange,
  className,
}) {
  return (
    <div className={`flex mt-2 justify-center ${className}`}>
      {Array.from({ length: testimonials.length }).map((a, index) => (
        <NavButtonMobile
          key={index}
          active={activeTestimonialIndex === index}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  );
}
const TestimonialQuote = ({
  theme,
  className,
  activeTestimonialIndex,
  onChange,
}) => {
  return (
    <div
      className={`relative  p-5 ${
        theme === "primary" ? "text-white bg-primary" : "text-primary bg-white"
      } ${className}`}
    >
      <h3 className="text-lg mb-2 font-bold">
        {testimonials[activeTestimonialIndex].title}
      </h3>
      <p className="text-sm mb-4  md:mb-5 text-black">
        {testimonials[activeTestimonialIndex].description}
      </p>

      <p className="text-sm font-bold text-opacity-75">
        {testimonials[activeTestimonialIndex].name}
      </p>
      <p className=" text-sm font-medium  text-opacity-75">
        {testimonials[activeTestimonialIndex].designation}
      </p>

      <div className="hidden lg:flex absolute right-0 bottom-0">
        <NavButtonDesktop
          nextOrPrev="prev"
          disabled={activeTestimonialIndex === 0}
          onClick={() =>
            activeTestimonialIndex !== 0 && onChange(activeTestimonialIndex - 1)
          }
        />
        <NavButtonDesktop
          nextOrPrev="next"
          disabled={activeTestimonialIndex === testimonials.length - 1}
          onClick={() =>
            activeTestimonialIndex !== testimonials.length - 1 &&
            onChange(activeTestimonialIndex + 1)
          }
        />
      </div>
    </div>
  );
};

export { TestimonialQuote, NavButtonMobileWrapper };
