import React from "react";
import SectionTitle from "./section-title";
function VFInNumbers({data}) {
  function Number({title, number}) {
    return (
      <div className="flex flex-col justify-center">
        <div className="text-black text-sm md:text-base md:mb-6"> {title}</div>
        <div className="text-primary text-lg md:text-4xl font-bold">
          {number}
        </div>
      </div>
    );
  }
  return (
    <div className="mb-12 md:mb-20 ">
      <div className="flex justify-between  mb-6 md:mb-12 items-center">
        <SectionTitle title="VentureFriends in a nutshell" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-vfInNumbers row-gap-8 md:row-gap-12 items-start">
        {/* <Number
          title={
            <span>
              Companies <br /> funded
            </span>
          }
          number={data.companiesFunded}
        /> */}
        <Number
          title={
            <span>
              Minimum initial <br />
              ticket
            </span>
          }
          number={data.minInitialTicket}
        />
        <Number
          title={
            <span>
              Largest initial
              <br />
              ticket so far
            </span>
          }
          number={data.lgtInitialTicket}
        />
        <Number
          title={
            <span>
              Target
              <br />
              Stage
            </span>
          }
          number={data.targetStage}
        />

        {/* <Number
          title={
            <span>
              Total <br /> AUM
            </span>
          }
          number={data.totalAUM}
        /> */}
        <Number
          title={
            <span>
              Initial to Follow on <br />
              investment ratio
            </span>
          }
          number={data.invRatio}
        />
        <Number
          title={
            <span>
              Latest
              <br />
              fund raised
            </span>
          }
          number={data.latestInvestmentRaised}
        />
        <Number
          title={
            <span>
              Geographical <br /> Focus
            </span>
          }
          number={data.geographicalFocus}
        />
      </div>
    </div>
  );
}

export default VFInNumbers;
